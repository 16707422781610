/** Apexchart **/
$background: hsl(var(--card));
$foreground: hsl(var(--foreground));
$border: hsl(var(--border));

$box-shadow-color: rgba(0, 0, 0, 0.3);

.apexcharts-tooltip {
  /** Dark mode */
  &.apexcharts-theme-dark {
    box-shadow: 0px 0px 30px 0px $box-shadow-color;
    border-radius: 0.475rem;
    border: 0 !important;
    background: $background !important;
    color: $foreground;

    .apexcharts-tooltip-title {
      background: $background !important;
      font-weight: 500;
      color: $foreground;
      border-bottom: 1px solid $border !important;
    }
  }
  .apexcharts-tooltip-title {
    padding: 0.5rem 1rem;
  }

  /** Light mode */
  &.apexcharts-theme-light {
    box-shadow: 0px 0px 30px 0px $box-shadow-color;
    border-radius: 0.475rem;
    border: 0 !important;
    background: $background !important;
    color: $foreground;

    .apexcharts-tooltip-title {
      background: $background !important;
      font-weight: 500;
      color: $foreground;
      border-bottom: 1px solid $border !important;
    }
  }
  .apexcharts-tooltip-title {
    padding: 0.5rem 1rem;
  }
}

.apexcharts-xaxistooltip {
  /** Dark mode */
  &.apexcharts-theme-dark {
    border-radius: 0.475rem;
    box-shadow: 0px 0px 30px $box-shadow-color;
    border: 0 !important;
    background: $background !important;
    color: $foreground;

    &:before {
      border-bottom: 0 !important;
    }

    &:after {
      border-bottom-color: $background !important;
    }
  }

  /** Light mode */
  &.apexcharts-theme-light {
    border-radius: 0.475rem;
    box-shadow: 0px 0px 30px $box-shadow-color;
    border: 0 !important;
    background: $background !important;
    color: $foreground;

    &:before {
      border-bottom: 0 !important;
    }

    &:after {
      border-bottom-color: $border !important;
    }
  }
}
