@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500;700&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222 47% 11%;
    --card: 210 40% 96%;
    --card-foreground: 240 0% 0%;
    --primary: 347 77% 50%;
    --primary-foreground: 0 0% 100%;
    --muted: 213 27% 84%;
    --muted-foreground: 215 16% 47%;
    --destructive: 350 100% 40%;
    --destructive-foreground: 0 0% 98%;
    --border: 214 32% 91%;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 95%;
    --card: 0 0% 7%;
    --card-foreground: 0 0% 14.9%;
    --primary: 347 77% 50%;
    --primary-foreground: 0 0% 100%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --destructive: 350 100% 40%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
  }

  :root[data-theme='violet'] {
    --primary: 281 83% 56%;
  }

  .dark[data-theme='violet'] {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 222 47% 7%;
    --card-foreground: 210 20% 98%;
    --primary: 262 83% 56%;
    --primary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --destructive: 350 100% 40%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
  }

  :root[data-theme='cappuccino'] {
    --primary: 30 30% 51%;
    --background: 0 0% 100%;
    --foreground: 33 32% 25%;
    --card: 29 30% 90%;
    --card-foreground: 33 32% 25%;
    --muted: 30 20% 80%;
    --muted-foreground: 32 25% 40%;
    --destructive: 350 100% 40%;
    --destructive-foreground: 29 79% 95%;
    --border: 30 25% 75%;
  }

  .dark[data-theme='cappuccino'] {
    --background: 33 32% 25%;
    --foreground: 29 59% 85%;
    --card: 32 32% 30%;
    --card-foreground: 29 59% 85%;
    --primary: 30 41% 66%;
    --primary-foreground: 33 32% 25%;
    --muted: 32 35% 45%;
    --muted-foreground: 29 35% 75%;
    --destructive: 350 100% 40%;
    --destructive-foreground: 29 59% 85%;
    --border: 32 25% 40%;
  }

  :root[data-theme='red'] {
    --primary: 350 100% 40%;
  }

  .dark[data-theme='red'] {
    --primary: 350 100% 40%;
    --primary-foreground: 0 85.7% 97.3%;
  }

  :root[data-theme='blue'] {
    --primary: 221.2 83.2% 53.3%;
  }

  .dark[data-theme='blue'] {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222 47% 7%;
    --card-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --destructive: 350 100% 40%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
  }

  :root[data-theme='sage-green'] {
    --primary: 90, 16.83%, 60.39%;
  }
  
  .dark[data-theme='sage-green'] {
    --primary:  90, 16.83%, 60.39%;
  }

  :root[data-theme='orange'] {
    --primary: 24.6 95% 53.1%;
  }

  .dark[data-theme='orange'] {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 24 10% 7%;
    --card-foreground: 60 9.1% 97.8%;
    --primary: 20.5 90.2% 48.2%;
    --primary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --border: 12 6.5% 15.1%;
  }

  :root[data-theme='yellow'] {
    --primary: 47.9 95.8% 53.1%;
  }

  .dark[data-theme='yellow'] {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 24 10% 7%;
    --card-foreground: 60 9.1% 97.8%;
    --primary: 47.9 95.8% 53.1%;
    --primary-foreground: 26 83.3% 14.1%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
  }

  :root[data-theme='green'] {
    --primary: 142.1 76.2% 36.3%;
  }

  .dark[data-theme='green'] {
    --primary: 142.1 70.6% 45.3%;
    --primary-foreground: 144.9 80.4% 10%;
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply w-full rounded-md border border-border bg-background px-3 py-3 text-xs text-foreground placeholder-muted-foreground focus:z-10 focus:border-primary focus:outline-none focus:ring-primary;
  }

  [type='checkbox'],
  [type='radio'] {
    @apply h-4 w-4 rounded border-border bg-background text-primary focus:ring-2 focus:ring-primary;
  }
}

@layer components {
  .dropdown-content {
    @apply pointer-events-none scale-95 opacity-0 duration-100 ease-in;
  }

  .dropdown:hover > .dropdown-content {
    @apply pointer-events-auto block scale-100 animate-fade-in-up opacity-100 duration-200;
  }
}

/** Scroll bar **/
@supports selector(::-webkit-scrollbar) {
  .supports-scrollbars\: pr-2 {
    padding-right: 0.5rem;
  }
}

/** Plugins **/
@import './assets/styles/apexchart.scss';
